import styled from 'styled-components';

import { darkText, darkBlue } from '../colors';

const StackContainer = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 1.2em;
  flex: 1;
  margin-left: 20px;
`;

const Title = styled.h2`
  margin-top: 0;
  padding-top: 0;
  color: ${darkText};
  margin-bottom: 1em;
`;

const Label = styled.div`
  color: ${darkText};
`;

const Value = styled.div`
  color: ${darkBlue};
  margin-bottom: 1em;
  font-weight: 700;
`;

const Stack = () => {
  return (
    <StackContainer>
      <Title>Our Standard Stack</Title>
      <Label>Database</Label>
      <Value>MySQL</Value>
      <Label>Back End</Label>
      <Value>Ruby on Rails</Value>
      <Label>Front End</Label>
      <Value>React</Value>
      <Value>
        We can develop in any language. Don't see the one you use?
        Just reach out and we can help.
      </Value>
    </StackContainer>
  );
};

export default Stack;
