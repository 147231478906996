import { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import logo from './preflight_logo.png';
import { darkBlue, darkText, actionBlue } from '../../colors';
import { COLLAPSE_WIDTH } from '../../styles';

const MenuBar = styled.div`
  position: fixed;
  height: 70px;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 100;
  background-color: RGBA(104,104,104,0.8);
  font-family: 'Baloo2-Regular';

  &.little {
    height: 70px;
  }
`;

const Logo = styled.img`
  transition: all linear .2s;
  margin-left: 100px;

  .big & {
    height: 50px;
    margin-top: 10px;
  }

  .little & {
    height: 50px;
    margin-top: 10px;
  }
`;

const Items = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  height: 24px;

  list-style-type: none;
  margin-right: 0px;
  margin-top: 10px;
  padding: 0;

  @media(max-width: ${COLLAPSE_WIDTH}) {
    display: none;
    flex-direction: column;
    position: fixed;
    right: 0;
    top: 0;
    background-color: white;
    box-shadow: 0px 4px 50px rgba(0, 36, 64, 0.12);
    height: 100%;
    min-height: 100vh;
    margin: 0px;
    z-index: 9999;
    animation: fadeIn 500ms;
    width: 80%;

    &.show {
      display: flex;
    }
  }
`;

const MenuItem = styled.li`
  font-family: 'Baloo2-Regular';
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  letter-spacing: 0.02em;
  padding: 10px;


  color: white;
  margin-right: 20px;

  a {
    color: white;
    text-decoration: none;
  }

  @media(max-width: 1400px) {
    margin-right: 12px;
  }

  @media(max-width: ${COLLAPSE_WIDTH}) {
    border-radius: 0;
    margin: 0;
    padding-right: 20px;
    padding-left: 20px;
     a {
      color: black;
     }
  }
`;

const Action = styled(MenuItem)`
  background-color: ${actionBlue};
  border-radius: 00px;
  padding-right: 20px;
  padding-left: 20px;
  color: white;
  cursor: pointer;

  @media(max-width: ${COLLAPSE_WIDTH}) {
    border-radius: 0;
    width: 100%;
    a {
      color: black;
    }
  }
`;

const Login = styled(Action)`
  background-color: rgba(255, 255, 255, 0.4);
  color: ${darkText};
  margin-left: 40px;

  @media(max-width: 1400px) {
    margin-left: 16px;
  }

  @media(max-width: ${COLLAPSE_WIDTH}) {
    margin-left: 0px;
  }
`;

const LoginOptions = styled.div`
  position: absolute;
  background-color: white;
  border: 1px solid ${darkText};
  border-radius: 5px;

  a {
    display: block;
    margin: 10px;
    color: black;
  }
`;

const MenuLine = styled.div`
  width: 24px;
  height: 3px;
  border-radius: 2px;
  background-color: ${darkText};
  margin: 6px 0;
  cursor: pointer;
`;

const MenuIcon = styled.div`
  display: none;

  @media (max-width: ${COLLAPSE_WIDTH}) {
    display: block;
    align-self: top;
    margin-top: 20px;
    margin-right: 24px;
    a {
      color: black;
    }

    .show & {
      margin-top: 4px;
      margin-bottom: 32px;
      margin-left: auto;
      margin-right: 0px;
    }
  }
`;

const CloseIcon = styled.div`
  display: none;

  @media (max-width: ${COLLAPSE_WIDTH}) {
    display: block;
    cursor: pointer;
    text-align: right;
    font-size: 2em;
    margin-left: auto;
    margin-right: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

const Menu = ({ menuClass, scrollToContact }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showLoginOptions, setShowLoginOptions] = useState(false);


  return (
    <MenuBar className={menuClass}>
      <Link to="/">
        <Logo src={logo} alt="preflight" />
      </Link>

      <MenuIcon onClick={() => setShowMenu(!showMenu)}>
        <MenuLine />
        <MenuLine />
        <MenuLine />
      </MenuIcon>

      <Items className={showMenu ? 'show' : 'hide'}>
        <CloseIcon onClick={() => setShowMenu(!showMenu)}>X</CloseIcon>
        <MenuItem>
          <Link to="/pti/services" onClick={() => setShowMenu(!showMenu)}>What We Offer</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/pti/case-studies" onClick={() => setShowMenu(!showMenu)}>Success Stories</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/pti/who-we-are" onClick={() => setShowMenu(!showMenu)}>Who We Are</Link>
        </MenuItem>
        <Login onClick={() => setShowLoginOptions(!showLoginOptions)}>
          Login
          {showLoginOptions && (
            <LoginOptions>
              <a href="https://preflighttech.com/admin/clients" onClick={() => setShowMenu(!showMenu)}>
                Employee Login
              </a>
              <a href="https://preflighttech.com/clients/dashboard" onClick={() => setShowMenu(!showMenu)}>
                Client Login
              </a>
            </LoginOptions>
          )}
        </Login>
        <Action onClick={scrollToContact}>
          Get Started
        </Action>
      </Items>
    </MenuBar>
  );
};

export default Menu;
