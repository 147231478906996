import styled from 'styled-components';

import {
  Page, NormalSection, HighlightSection, Header, TopTag, Title,
  Content, ContentTag, TextGroup, DropBox,
} from './components';

import flyLouieLogo from './images/fly-louie-logo.png';
import fpxLogo from './images/fpx-logo.png';
import ashtonLogo from './images/ashton-logo.png';
import jdmLogo from './images/jdm-logo.jpg';
import tfLogo from './images/tf-logo.png';
import adsLogo from './images/ads-logo.png';

const Logo = styled.img`
  margin: 10px;
  max-width: 500px;
  width: 90%
`;

const Blockquote = styled.p`
  font-style: italic;
`;

const CaseStudies = () => {
  return (
    <Page>
      <NormalSection>
        <Header>
          <TopTag>Succeeding When Our Clients Succeed</TopTag>
          <Title>Case Studies</Title>
        </Header>
      </NormalSection>
      <HighlightSection className="dark-highlight">
        <Content>
          <div style={{position: 'relative'}}>
            <ContentTag>
              Fly Louie
            </ContentTag>
            <TextGroup>
              <Logo src={flyLouieLogo} alt="Fly Louie" />
              <p>
                In 2017, Fly Louie launches with a goal of connecting
                independent charter operators and travelers with the
                best in private aviation services.
                Preflight Technologies helped Fly Louie streamline away
                from spreadsheets and out-of-the-box software so they
                could focus on generating business.
              </p>
              <Blockquote>
                I highly recommend Preflight Technologies to any company
                looking for software development help. They have
                completely transformed the way we use technology to
                power our business. Before Preflight Technologies, we mostly
                relied on spreadsheets and highly manual processes. Mike and
                his team took the time to deeply understand our tools and
                systems, drafted the largest process map I’ve ever seen,
                and then went to work moving it all into a database with
                automation and tracking. Now, the work that most of our
                team used to do is handled by the system they built for us.
                What’s been so great about Mike and his team is
                how active they are in conversations about product strategy
                and business strategy. Most importantly, Mike is just a
                really good guy. He cares tremendously about doing a
                great job, and he’s a fantastic person to do business with.
                I highly recommend PTI and would be happy to speak further
                with anyone interested in working with them.<br />
                - Eytan, COO, Fly Louie
              </Blockquote>
            </TextGroup>
            <DropBox />
          </div>
        </Content>
      </HighlightSection>
      <NormalSection>
        <Content>
          <ContentTag>
            Floorplan Xpress
          </ContentTag>
          <TextGroup>
            <Logo src={fpxLogo} alt="Floorplan Xpress Logo" />
            <p>
              Floorplan Xpress is a nation-wide leader in inventory financing
              for the used-car industry. The Preflight Team has built their
              software from the ground up, starting in 2010. We started by
              replacing an outdated single-entry accounting system with no
              reporting power, and then we expanded it to provide a double-entry,
              audited accounting system that provides real-time data and
              analytics. We created software that allows for real-time
              auditing in the field using mobile technology. Our partnership
              with Floorplan Xpress continues to expand as we work hard to
              create a more powerful system.
            </p>
          </TextGroup>
        </Content>
      </NormalSection>
      <HighlightSection className="dark-highlight">
        <Content>
          <div style={{position: 'relative'}}>
            <ContentTag>
              Ashton Agency
            </ContentTag>
            <TextGroup>
              <Logo src={ashtonLogo} alt="Ashton Agency Logo" />
              <p>
                Since 1968, Ashton Agency has been building relationships
                with business across the United States by offering simple
                solutions to surety bond and special insurance needs. We
                teamed up with Ashton Agency in 2013 to update their CRM.
                By changing their system, and integrating with other partners,
                we have helped Ashton Agency increase efficiency and reduce
                overhead, allowing them to increase their profits.
              </p>
              <Blockquote>
                Having had the pleasure of working with the team at Preflight
                Technologies since the business was founded, I can tell
                you that their commitment to providing exemplary services
                is unmatched. Our system developers, Jared and Mike Morgan,
                are true innovators. They’ve worked on a multitude of
                projects and system improvements for our agency over the past
                5 years and have always found a way to make our visions a
                reality. Steve, our account liaison, is very attentive
                to our needs. With scheduled calls every two weeks, we know
                Preflight is making our business a priority. A truly wonderful
                experience from a company that values their clients more than
                their bottom line.<br />
                - Danielle, VP Operations, Ashton
              </Blockquote>
            </TextGroup>
            <DropBox />
          </div>
        </Content>
      </HighlightSection>
      <NormalSection>
        <Content>
          <ContentTag>
            JD Machine Corp.
          </ContentTag>
          <TextGroup>
            <Logo src={jdmLogo} alt="JD Machine Corp. Logo" />
            <Blockquote>
              Preflight worked with us the entire way through our project
              and were very clear and direct. They were also very workable
              and listened to our concerns and needs. Their internal
              communication tool kept us up to date and helped us escalate
              work items through their system. I would recommend Preflight
              based on how well they worked with us to understand our needs
              and work directly with us to deliver our application to
              reduce internal clerical tasks.<br />
              - Gary Squire, JD Machine Corp.
            </Blockquote>
          </TextGroup>
        </Content>
      </NormalSection>
      <HighlightSection className="dark-highlight">
        <Content>
          <ContentTag>
            Accelerated Dealer Services
          </ContentTag>
          <div style={{position: 'relative'}}>
            <TextGroup>
              <Logo src={adsLogo} alt="ADS Logo" />
              <p>
                ADS knows cars. They are a team of retail car people turned
                digital marketing specialists. Making marketing easy, fast
                and profitable for our clients is what they do.
              </p>
              <p>
                Preflight Technologies helped ADS increase the productivity of
                their inbound call and text message team by creating new tools
                using Twilio's API. We created new features that allowed ADS
                to act as an extension of their clients.
              </p>
            </TextGroup>
            <DropBox />
          </div>
        </Content>
      </HighlightSection>
      <NormalSection>
        <Content>
          <ContentTag>
            Turbulence Forecast
          </ContentTag>
          <TextGroup>
            <Logo src={tfLogo} alt="Turbulence Forecast Logo" />
            <p>
              Turbulence Forecast provides nervous flyers a way to understand
              what to expect on their flights. Preflight Technologies is
              building an updated iOS and Android application and creating new
              forecasting tools to help flyers get information more quickly.
            </p>
          </TextGroup>
        </Content>
      </NormalSection>
    </Page>
  );
};

export default CaseStudies;
