import styled from 'styled-components';

import {
  Page, NormalSection, HighlightSection,
  Header, Title, BottomTag,
  Content, ContentTag, TextGroup, DropBorder, DropBox, ContentImage
} from './components';

import runwayImage from './images/runway.jpg';
import teamImage from './images/team.jpg';

const TeamImage = styled(ContentImage)`
  width: 100%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  display: block;
`;

const TeamList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
  max-width: 1000px;
`;

const TeamCard = styled.div`
  min-width: 180px;
  margin-top: 10px;
`;


const TeamName = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 1.3em;
`;


const TeamTitle = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 1.0em;
  color: gray;
`;

const WhoHeader = styled(Header)`
  background-image: url(${runwayImage});
  background-repeat: no-repeat;
  background-size: 1460px auto;
  padding-top: 60px;
  padding-right: 20px;
  min-height: 600px;
  font-size: 1.3em;

  @media(max-width: 1000px) {
    font-size: 1em;
  }
`;

const WhoTitle = styled(Title)`
  text-shadow: 1px 1px white;
`;

const WhoTag = styled(BottomTag)`
  color: black;
`;

const WhoWeAre = () => {
  return (
    <Page>
      <NormalSection>
        <WhoHeader>
          <WhoTitle>
            Preparing Your Business<br />
            For Takeoff
          </WhoTitle>
          <WhoTag>Love the Journey</WhoTag>
        </WhoHeader>
        <Content>
          <ContentTag>
            FIRST CLASS EXPERIENCE
          </ContentTag>
          <TextGroup>
            <DropBorder />
            <p>
              At Preflight, we challenge the status quo to plan and build
              impactful human experiences that win hearts and move markets.
              We partner with passionate leaders and visionary entrepreneurs,
              then apply creativity, design and data to re-invent
              businesses, drive growth and orchastrate a client-centered
              approach. Together we strive to shape a
              sustainable and inclusive future for businesses and people.
            </p>
          </TextGroup>
        </Content>
        <Content>
          <ContentTag>
            Our History
          </ContentTag>
          <TextGroup>
            <DropBorder />
            <p>
              Preflight Technologies was founded in 2017 when brothers, Mike
              and Jared Morgan, decided to combine their passions for life to
              create an American based technology company that helps business
              owners like you take control of your projects and profits.
            </p>
            <p>
              Our passion drives us to be innovative, creative, and exploring
              our love of technology keeps us learning, motivated,
              and excited about what we do.
            </p>
          </TextGroup>
        </Content>
      </NormalSection>
      <HighlightSection className="dark-highlight">
        <Header>
          <Title>Our Culture</Title>
          <BottomTag>Five Guiding Principles</BottomTag>
        </Header>
        <Content>
          <ContentTag>
            Our Vision of Clear Skies for Business Owners Worldwide
          </ContentTag>
          <div style={{position: 'relative'}}>
            <TextGroup>
              <DropBorder />
              <p>
                Here at Preflight Technologies, every single member of our
                team focuses on 5 Guiding Principles:
              </p>
              <ol>
                <li>
                  <b>People First.</b>
                  In everything that we do, our goal is to put people first.
                  We believe that people matter more than any metrics or
                  algorithms.
                </li>
                <li>
                  <b>Be Present.</b>
                  Wherever we are, we’re fully present in the moment. Mike, our
                  CEO and founder, loves to travel… but it’s about so much
                  more than getting on a plane.  It’s about being present in
                  the moment with those around you.
                </li>
                <li>
                  <b>Be a Two-Year-Old.</b>
                  Be curious, be adventurous, be brave. We always remember the
                  joy and excitement of learning something new.
                </li>
                <li>
                  <b>Be Your Own Competition.</b>
                  We’re always hungry to improve. The better we are, the more
                  we can help you succeed.
                </li>
                <li>
                  <b>Let Data Drive, But Trust Your Gut.</b>
                  We wouldn’t be a software company if we didn’t love data.
                  However, we believe in trusting your gut. It could lead you
                  to success you never imagined.
                </li>
              </ol>
              <p>
                Contact us today to learn more about how our team can help
                your business soar.
              </p>
            </TextGroup>
          <DropBox />
          </div>
        </Content>
      </HighlightSection>
      <NormalSection>
        <Content>
          <ContentTag>
            Meet Our Team
          </ContentTag>
          <TeamImage src={teamImage} alt="Preflight Team Members" />
          <TeamList>
            <TeamCard>
              <TeamName>Steve Morgan</TeamName>
              <TeamTitle>Project Manager</TeamTitle>
            </TeamCard>
            <TeamCard>
              <TeamName>Jared Morgan</TeamName>
              <TeamTitle>CTO</TeamTitle>
            </TeamCard>
            <TeamCard>
              <TeamName>Sabrina Yaw</TeamName>
              <TeamTitle>COO</TeamTitle>
            </TeamCard>
            <TeamCard>
              <TeamName>Mike Morgan</TeamName>
              <TeamTitle>CEO</TeamTitle>
            </TeamCard>
            <TeamCard>
              <TeamName>Gary Michaud</TeamName>
              <TeamTitle>Senior Developer</TeamTitle>
            </TeamCard>
          </TeamList>
        </Content>
      </NormalSection>
    </Page>
  );
};

export default WhoWeAre;
