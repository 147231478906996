import styled from 'styled-components';

import { MAX_WIDTH } from '../styles';
import { darkBlue, lightBlue, veryLightBlue } from '../colors';

export const Page = styled.div`
  padding-top: 20px;
`;

export const NormalSection = styled.article`
  margin: 40px;
`;

export const HighlightSection = styled.article`
  background-color: ${darkBlue};
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 20px;
`;

export const Header = styled.header`
  max-width: ${MAX_WIDTH};
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
  padding-left: 60px;

  .dark-highlight & {
    margin-top: 3em;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const TopTag = styled.h1`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 1em;
  letter-spacing: 0.6em;
  text-transform: uppercase;
  color: #111111;
  margin-bottom: 1em;
`;

export const Title = styled.h2`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 2.8em;
  line-height: 120%;
  color: #111111;
  margin-top: 0.2em;
  margin-bottom: 0.2em;

  .dark-highlight & {
    text-align: center;
    color: white;
  }
`;

export const BottomTag = styled.p`
  font-family: 'Nunito Sans', sans-serif;
  font-style: italic;
  font-weight: 300;
  font-size: 1.2em;
  line-height: 100%;
  color: rgba(49, 46, 42, 0.5);

  .dark-highlight & {
    margin-top: 0;
    text-align: center;
    color: white;
  }
`;

export const Cover = styled.section`
  max-width: 100%;
`;

export const Content = styled.section`
  margin-top: 3em;
  max-width: ${MAX_WIDTH};
  margin-left: auto;
  margin-right: auto;

  .dark-highlight-padded {
    padding-left: 20px;
    padding-right: 60px;
  }
`;

export const ContentTag = styled.h3`
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 2em;
  line-height: 160%;
  margin-bottom: 0.5em;

  color: ${darkBlue};

  .dark-highlight & {
    color: white;
    font-size: 1.8em;
    margin-bottom: 0.2em;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const TextGroup = styled.div`
  position: relative;
  background: #FFFFFF;
  box-shadow: 1px 2px 24px rgba(41, 44, 124, 0.1);
  border-radius: 8px;
  border-left: 8px solid ${lightBlue};
  padding: 2px 30px;

  .dark-highlight & {
    border-left: 8px solid ${veryLightBlue};
    margin-right: 40px;
    margin-bottom: 40px;
    z-index: 2;
  }

  p, li {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 400;
    font-size: 1.5em;
    line-height: 120%;
    padding-left: 20px;
    padding-right: 20px;
  }

  li b {
    margin-right: 0.3em;
  }

  li {
    margin-bottom: 0.5em;
  }
`;

export const DropBorder = styled.div`
  border-left: 1px solid #cccccc;
  position: absolute;
  top: 28px;
  height: 25%;
  min-height: 1em;
`;

export const DropBox = styled.div`
  position: absolute;
  background-color: ${veryLightBlue};
  right: -40px;
  bottom: -40px;
  width: 200px;
  height: 200px;
  border-radius: 8px;
  z-index: 1;
`;

export const ContentImage = styled.img`
  box-shadow: 0 10px 16px 0 rgba(0,0,0,0.5), 0 6px 20px 0 rgba(0,0,0,0.19)
`;
