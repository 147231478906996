import { Route, Routes as Switch } from 'react-router-dom';
import Home from './pages/Home';
import Solutions from './pages/Solutions';
import CaseStudies from './pages/CaseStudies';
import WhoWeAre from './pages/WhoWeAre';
import Privacy from './pages/Privacy';

const Routes = ({ scrollToContact }) => {
  return (
    <Switch>
      <Route path="/" element={<Home scrollToContact={scrollToContact} />} />
      <Route path="/pti/solutions" element={<Solutions />} />
      <Route path="/pti/services" element={<Solutions />} />
      <Route path="/pti/case-studies" element={<CaseStudies />} />
      <Route path="/pti/who-we-are" element={<WhoWeAre />} />
      <Route path="/pti/privacy" element={<Privacy />} />
    </Switch>
  )
};

export default Routes;
