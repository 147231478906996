import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro';

import logo from './preflight_logo_white.png';
import { darkBlue, lightBlue } from '../../colors';

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${darkBlue};
  justify-content: center;
  padding-bottom: 20px;

  @media(max-width: 768px) {
    flex-direction: column;
  }
`;

const Logo = styled.img`
  width: 100%;
`;

const Column = styled.div`
  font-family: 'Poppins', sans-serif;
  color: white;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 200px;
  margin-top: 40px;
  margin-left: 20px;
  margin-right: 20px;

  a {
    color: white;
    text-decoration: none;
  }
`;

const Double = styled(Column)`
  flex: 2;
  max-width: 300px;
`;

const ColumnHead = styled.div`
  font-size: 1.2em;
  font-weight: 700;
  margin-bottom: 20px;
`;

const Social = styled.div`
  display: block;

  a {
    display: inline-block;
    background-color: white;
    border-radius: 50%;
    color: ${lightBlue};
    width: 1.4em;
    height: 1.4em;
    padding: 10px;
    font-size: 1.5em;
    text-align: center;
    margin-right: 10px;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <Double style={{marginTop: 0}}>
        <Logo src={logo} />
        <p>
          Enterprise Solutions<br/>
          For Growing Businesses
        </p>
      </Double>
      <Column>
        <ColumnHead>Company</ColumnHead>
        <Link to="/pti/who-we-are">About</Link>
        <Link to="/pti/privacy">Privacy Policy</Link>
      </Column>
      <Column>
        <ColumnHead>More</ColumnHead>
        <Link to="/pti/solutions">Solutions</Link>
        <Link to="/pti/case-studies">Case Studies</Link>
      </Column>
      <Column>
        <ColumnHead>Login</ColumnHead>
        <a href="https://preflighttech.com/admin/clients">
          Employee
        </a>
        <a href="https://preflighttech.com/clients/dashboard">
          Client
        </a>
      </Column>
      <Double style={{marginTop: 24}}>
        <Social>
          <a
            href="https://www.facebook.com/preflighttech/"
            target="_blank"
            rel="noreferrer"
            title="Facebook"
          >
            <FontAwesomeIcon icon={brands('facebook-f')} />
          </a>
          <a
            href="https://www.linkedin.com/company/preflight-technologies-incorporated/"
            target="_blank"
            rel="noreferrer"
            title="LinkedIn"
          >
            <FontAwesomeIcon icon={brands('linkedin')} />
          </a>
          <a
            href="https://github.com/preflighttech/"
            target="_blank"
            rel="noreferrer"
            title="Github"
          >
            <FontAwesomeIcon icon={brands('github')} />
          </a>
        </Social>
        <div style={{marginTop: 10}}>
          &copy; 2017-2023<br />
          Preflight Technologies Incorporated<br />
          813 NW 34th Street<br />
          Moore, OK 73160
        </div>
      </Double>
    </FooterContainer>
  );
};

export default Footer;
