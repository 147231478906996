import { useRef, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import styled from 'styled-components';
import ScrollToTop from "./components/scrollToTop";


import Pixel from './components/Pixel';
import Menu from './components/Menu';
import Contact from './components/Contact';
import Stack from './components/Stack';
import Footer from './components/Footer';
import Routes from './routes';
import { MAX_WIDTH, COLLAPSE_WIDTH } from './styles';

const ContactContainer = styled.div`
  display: flex;
  max-width: ${MAX_WIDTH};
  margin-left: auto;
  margin-right: auto;

  @media(max-width: ${COLLAPSE_WIDTH}) {
    flex-direction: column;
  }
`;

const App = () => {
  const contactRef = useRef(null);
  const [menuClass, setMenuClass] = useState('big');

  window.onscroll = () => {
    if (
      window.document.body.scrollTop > 50 ||
      window.document.documentElement.scrollTop > 50
    ) {
      setMenuClass('little');
    } else {
      setMenuClass('big');
    }
  };

  const scrollToContact = () => {
    contactRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <Router>
    <ScrollToTop />
      <div className="App">
        <Pixel />
        <Menu menuClass={menuClass} scrollToContact={scrollToContact} />
        <div style={{ marginTop: 60 }}>
          <Routes scrollToContact={scrollToContact} />
        </div>
        <ContactContainer>
          <div ref={contactRef}></div>
          <Contact />
          <Stack />
        </ContactContainer>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
