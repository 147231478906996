import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const Pixel = () => {
  const location = useLocation();
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    if (showImage) {
      setShowImage(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  if (!showImage) {
    setTimeout(() => setShowImage(true), 1000);
    return null;
  }

  return (
    <img
      src="https://id.rlcdn.com/712089.gif?cparams=cid%3D3b10cf79-1153-4055-b85a-2a4ee7a2a4ee%26pid%3D9eea6499-4651-4735-8078-33a87e3a385c%26referral_source%3Dwebsite"
      width="1"
      height="1"
      alt=""
      style={{position: 'absolute'}}
    />
  );
};

export default Pixel;
