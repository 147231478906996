import styled from 'styled-components';
import { darkBlue, darkText, actionBlue } from '../colors';

import {
  Page, NormalSection, HighlightSection,
  Header, Title, BottomTag, Cover, MenuItem,
  Content, ContentTag, TextGroup, DropBorder, DropBox, ContentImage
} from './components';

import landingPage from './images/2023-Landing-Page.png';
import jetTakeoff from './images/jet-takeoff.jpeg';
import meeting from './images/meeting.jpg';
import headway from './images/headway.png';

const TopTitle = styled(Title)`
  font-size: 2.8em;
  text-align: center;
  margin-right: 20px;
  font-family: BakbakOne-Regular;

  @media(max-width: 1000px) {
    font-size: 2.0em;
  }
`;

const ImageContainer = styled(Cover)`
  margin-top: -80px;

  img {
    display: block;
    width: 100%;
    height: auto;
    box-shadow: none; 
  }

  @media(max-width: 1400px) {
    flex-direction: column;
    margin-top: -10px;

    img {
      width: 100%;
      box-shadow: none;

    }
  }
`;

const OverlayText = styled(Cover)`
  position: absolute;
  top: 50%; 
  left: 10%; 
  transform: translate(0%, -50%); 
  color: #fff; 
  padding: 10px 20px; 
  font-size: 20px; 
  text-align: left;
  font-family: Baloo2-Regular;
  font-size: 3em;

  @media(max-width: 1000px) {
    color: #000;
    font-size: 1.5em;
    display: inline;
    position: relative;
    top: 100%;
    left: 0;
  }
`;

const OverlayTextTitle = styled(Cover)`
  font-family: BakbakOne-Regular;
  text-transform: capitalize;
  font-size: 1.5em
`;

const SectionTwentyThree = styled(HighlightSection)`
  padding-top: 48px;
`;

const TopContentTwentyThree = styled(Content)`
  display: flex;
  margin-top: 0;
  margin-bottom: 0;

  img {
    height: 320px;
    width: 540px;
    object-fit: cover;
    float: right;
    margin: 20px;
  }

  @media(max-width: 1400px) {
    flex-direction: column;

    img {
      margin-top: 20px;
      margin-left: 0;
      width: 100%;
      height: 200px;
    }
  }
`;

const TopContent = styled(Content)`
  display: flex;

  img {
    margin-left: 20px;
    height: 560px;
    width: 260px;
    object-fit: cover;
  }

  @media(max-width: 1000px) {
    flex-direction: column;

    img {
      margin-top: 20px;
      margin-left: 0;
      width: 100%;
      height: 300px;
    }
  }
`;

const HomeHighlightSection = styled(HighlightSection)`
  padding-bottom: 0px;
  padding-top: 0px;
`;

const HighlightHeader = styled(Header)`
  padding-right: 140px;
`;

const HighlightContent = styled(Content)`
  display: flex;

  img {
    margin-right: 20px;
    height: 600px;
    width: 300px;
    object-fit: cover;
    margin-bottom: 0px;
  }

  @media(max-width: 1000px) {
    flex-direction: column;

    img {
      display: none;
    }
  }
`;

const MainHeader = styled(Header)`
  padding-top: 0px;
  margin-bottom: 0px;
  font-family: Baloo2-Regular;
  text-align: center;
`;

const MainHeaderSubtext = styled.span`
  font-size: 2em;
  font-family: Baloo2-Regular;
`;

const Action = styled.div`
  background-color: ${actionBlue};
  border-radius: 00px;
  padding-right: 20px;
  padding-left: 20px;
  color: white;
  cursor: pointer;
  display: inline;
  font-size: 0.7em;
`;

const Home = ({ scrollToContact }) => {
  return (
    <Page>
      <ImageContainer>
        <ContentImage src={headway} alt="Headway" />
        <OverlayText>
          <OverlayTextTitle>
            YOUR CHALLENGES, OUR EXPERTISE.
          </OverlayTextTitle>
          Custom software solutions unleashed.<br />
          <Action onClick={scrollToContact}>
            Get Started
          </Action>
        </OverlayText>
      </ImageContainer>
      <NormalSection>
        <MainHeader>
          <TopTitle>
           HELPING YOU LOVE THE JOURNEY.
          </TopTitle>
          <MainHeaderSubtext>
            We are more than a traditional dev shop. We are your co-pilot.<br />
            Mapping a clear path to your destination.
          </MainHeaderSubtext>
        </MainHeader>
      </NormalSection>


      <SectionTwentyThree className="dark-highlight">
        <TopContentTwentyThree>
          <TextGroup style={{display: 'flow-root'}}>
            <ContentImage src={landingPage} alt="Handshake" />
            <DropBorder />
            <p>
              We are more than a traditional Dev Shop. We are your
              Partner, your outsourced CTO, your Software Consultant.
            </p>
            <p>
              At Preflight Technologies, we create business efficiencies
              through technology by building custom software to fit the needs
              of your company now and in the future.
            </p>
            <p>
              How do you know if custom software is right for you?
              <br />
              <span
                style={{textDecoration: 'underline', cursor: 'pointer'}}
                onClick={scrollToContact}
              >
                Ask our team
              </span>
            </p>
          </TextGroup>
        </TopContentTwentyThree>
        <TopContentTwentyThree>
          <TextGroup>
            <p style={{fontStyle: 'italic'}}>
              “Mike and his team took the time to deeply understand our
              tools and systems, drafted the largest process map I’ve
              ever seen, and then went to work moving it all into a database
              with automation and tracking. Now, the work that most of our
              team used to do is handled by the system they built for us.”
              - Eytan, COO, Fly Louie
            </p>
          </TextGroup>
        </TopContentTwentyThree>
      </SectionTwentyThree>
      <NormalSection>
        <TopContent>
          <div>
            <Content style={{marginTop: 0}}>
              <ContentTag style={{marginTop: -10}}>
                Get Off the Runway
              </ContentTag>
              <TextGroup>
                <DropBorder />
                <p>
                  Are you an established business trying to fly with less
                  turbulence or a brand new company just trying to get off
                  the ground? Preflight Technologies is here to help.
                </p>
                <p>
                  We are a team of developers, architects, and project
                  managers with experience and a singular focus. Using our
                  experience and consideration, we create a custom look
                  and functionality for your software to lift your business
                  technology into the skies of success.
                </p>
              </TextGroup>
            </Content>
            <Content>
              <ContentTag>
                You Need a Reliable Co-Pilot
              </ContentTag>
              <TextGroup>
                <DropBorder />
                <p>
                  At Preflight Technologies, we pride ourselves on looking at
                  your business as if it were ours. By getting into this co-pilot
                  mindset, we’re not only more efficient on your projects... we’re
                  hyper-focused on them.
                </p>
              </TextGroup>
            </Content>
          </div>
          <ContentImage src={jetTakeoff} alt="Jet Taking Off" />
        </TopContent>
      </NormalSection>
      <HomeHighlightSection className="dark-highlight">
        <HighlightContent>
          <img src={meeting} alt="Meeting" />
          <div>
            <HighlightHeader>
              <Title>Helping Your Business Love the Journey</Title>
              <BottomTag>Mapping a Clear Path to Your Destination</BottomTag>
            </HighlightHeader>
            <Content className="dark-highlight-padded">
              <div style={{position: 'relative'}}>
                <TextGroup>
                  <DropBorder />
                  <p>
                    Our US-based team is dedicated to meeting your company’s
                    actual needs.
                    We do so by looking at three key areas of concern: your
                    immediate concerns, your long-term planning, and your
                    future growth.
                  </p>
                  <p>
                    We understand that achieving success means focusing on the
                    here and now while looking ahead for potential issues. When you
                    work with us, we’ll take care of your immediate needs, create a
                    long-term plan to prevent issues from reappearing later on, and
                    include steps to support your future growth.
                  </p>
                </TextGroup>
                <DropBox />
              </div>
            </Content>
          </div>
        </HighlightContent>
      </HomeHighlightSection>
    </Page>
  );
};

export default Home;
