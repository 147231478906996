import { useRef, useState } from 'react';
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';

import { darkBlue } from '../colors';

const ContactForm = styled.form`
  font-family: 'Poppins', sans-serif;
  font-size: 1.2em;
  flex: 2;
  margin-right: 20px;
`;

const ThankYou = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 2em;
  flex: 2;
  margin-right: 20px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 20px;
  margin-bottom: 20px;

  label {
    font-weight: 700;
  }

  input, textarea {
    font-family: 'Poppins', sans-serif;
    border: 1px solid lightgray;
    padding: 8px;
    border-radius: 4px;
  }
`;

const Action = styled.button`
  display: block;
  background-color: ${darkBlue};
  font-size: 1.5em;
  border: none;
  border-radius: 50px;
  padding: 16px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  color: white;
  cursor: pointer;
`;

const Errors = styled.div`
  color: red;
  font-weight: 700;
`;

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [contactSubmitted, setContactSubmitted] = useState(false);
  const [errors, setErrors] = useState(false);
  const recaptchaRef = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await recaptchaRef.current.executeAsync();

    if (email && phone && token) {
      fetch(
        'https://preflighttech.com/api/1/contacts/pti',
        {
          method: 'POST',
          headers: {
            Authorization: '4JknMKFWEXo9h2ytNfoglyHCXVHb42CO',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            contact: {
              name, email, phone, message, token
            }
          })
        }
      ).then(() => setContactSubmitted(true));
    } else {
      setErrors('We need either your email or phone number.')
    }
  }

  if (contactSubmitted) {
    return (
      <ThankYou>
        Thank You.<br />
        We look forward to being your co-pilot.
      </ThankYou>
    );
  }

  return (
    <ContactForm onSubmit={handleSubmit}>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="6LcqGOMgAAAAAN18Y96pRoN3oab4hPAiRqV7eaGW"
      />
      <Field>
        <label htmlFor="name">Name</label>
        <input
          name="name"
          type="text"
          value={name}
          onChange={e => setName(e.target.value)}
          placeholder="Your name"
        />
      </Field>
      <Row>
        <Field>
          <label htmlFor="email">Email</label>
          <input
            name="email"
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="Your Email"
          />
        </Field>
        <Field>
          <label htmlFor="phone">Phone Number</label>
          <input
            name="phone"
            type="tel"
            value={phone}
            onChange={e => setPhone(e.target.value)}
            placeholder="Your Phone"
          />
        </Field>
      </Row>
      <Field>
        <label htmlFor="message">How Can We Help?</label>
        <textarea
          name="message"
          value={message}
          onChange={e => setMessage(e.target.value)}
          rows="6"
          placeholder="Your message"
        />
      </Field>
      {errors && <Errors>{errors}</Errors>}
      <Action type="submit">Get Started</Action>
    </ContactForm>
  )
};

export default Contact;
