import styled from 'styled-components';

import {
  Page, NormalSection, HighlightSection,
  Header, TopTag, Title, BottomTag,
  Content, ContentTag, TextGroup, DropBorder, DropBox, ContentImage
} from './components';

import digitalChallengesImage from './images/services/landing-page-top.jpg';
import enterpriseImage from './images/services/gallery-6_original.jpg';
import mobileImage from './images/services/gallery-5_original.jpg';
import webImage from './images/services/iStock-1163132207.jpg';
import consultingImage from './images/services/iStock-891107760.jpg';

const SolutionsHeader = styled(Header)`
  padding-top: 40px;
  margin-bottom: 0px;
`;

const SolutionsTitle = styled(Title)`
  font-size: 2.8em;
  text-align: center;
  margin-right: 20px;

  @media(max-width: 1000px) {
    font-size: 2.0em;
  }
`;

const BoldText = styled.span`
  font-weight: 700;
`;

const DigitalChallengesImage = styled(ContentImage)`
  float: right;
  max-width: 40%;
  width: 600px;
  height: 300px;
  object-fit: cover;
  margin: 10px;
  margin-left: 20px;
`;

const EnterpriseImage = styled(DigitalChallengesImage)`
  float: left;
  max-width: 40%;
  margin-left: 0px;
  margin-right: 40px;
`;

const MobileImage = styled(DigitalChallengesImage)`
  height: 270px;
`;

const WebImage = styled(EnterpriseImage)`
  max-width: 50%;
  height: 360px;
`;

const ConsultingImage = styled(EnterpriseImage)`
  max-width: 30%;
`;

const Solutions = () => {
  return (
    <Page>
      <NormalSection>
        <SolutionsHeader>
          <SolutionsTitle>
            Solutions For Your Growing Businesses
          </SolutionsTitle>
        </SolutionsHeader>
        <Content>
          <ContentTag>
            Your Digital Challenges
          </ContentTag>
          <TextGroup style={{display: 'flow-root'}}>
            <DropBorder />
            <p>
              Are you spending too much time at the keyboard and not enough
              in front of customers?
              Are your employees working in multiple spreadsheets
              or off the shelf software that does not lineup with your SOPs?
            </p>

            <DigitalChallengesImage
              src={digitalChallengesImage}
              alt="Laptop on Desk"
            />

            <p>
              Do you have a strategy in place that enables you to adapt
              quickly and efficiently to new technologies and digital
              challenges across your organization?
            </p>

            <p>
              Imagine walking into your office, warehouse, or store
              and seeing your employees engaged with each other and
              your customers. Their focus isn't data entry or finding
              workarounds, instead, it's about solving the problems that
              your customers are facing. That's what happens when you build
              software that supports your processes.
            </p>
          </TextGroup>
        </Content>
      </NormalSection>
      <HighlightSection className="dark-highlight">
        <Content>
          <div style={{position: 'relative'}}>
            <TextGroup>
              <EnterpriseImage
                src={enterpriseImage}
                alt="Person using tablet"
              />

              <DropBorder />
              <p>
                <BoldText>Enterprise Solutions</BoldText>{' '}
                are designed to integrate multiple facets of a company's
                business through the interchange of information from various
                business process areas and related databases.
              </p>
              <p>
                We believe in building software that supports your business
                and its processes. Isn’t it so frustrating when a tool
                doesn’t match your workflow? Our custom ERP software development
                is aimed at building a scalable business-tailored system
                that integrates all business management functions and
                facilitates data-driven decision-making.
              </p>
            </TextGroup>
            <DropBox />
          </div>
        </Content>
      </HighlightSection>
      <NormalSection>
        <Content>
          <TextGroup>
            <MobileImage
              src={mobileImage}
              alt="Person with three mobile devices"
            />

            <DropBorder />
            <p>
              <BoldText>Mobile Application Development</BoldText>{' '}
              is the process of creating
              software applications that run on a mobile device.
            <p></p>
              People today expect ease and convenience from your mobile app,
              but they expect security too. Our team focuses on developing
              a quick, secure, and encrypted interface for everything from
              logging in to sharing payment info.
            <p></p>
              Whether your mobile app needs are big or small, we stay committed
              to establishing clear goals and taking steps to get there. From
              clocking in to tracking inventory, our experts have the
              experience and dedication to create a flawless, custom
              mobile solution for your business needs.
            </p>
          </TextGroup>
        </Content>
      </NormalSection>
      <HighlightSection className="dark-highlight">
        <Content>
          <div style={{position: 'relative'}}>
            <TextGroup>
              <WebImage
                src={webImage}
                alt="Desk with papers and devices"
              />

              <DropBorder />
              <p>
                <BoldText>Web Application Development</BoldText>{' '}
                is the creation of application programs that reside on
                remote servers and are delivered to the user's device over the
                Internet. A web application does not need to be downloaded and
                is instead accessed through a network.
              </p>
              <p>
                With us, you’ll get software that’s so much more than a
                customized CRM solution—you’ll get an experience that’s
                accessible to your customers and leadership alike. We treat
                your business like our very own, starting with understanding
                your core principles and goals. Then, we create your software
                with the features and functionalities you need for growth.
              </p>
            </TextGroup>
            <DropBox />
          </div>
        </Content>
      </HighlightSection>
      <NormalSection>
        <Content>
          <TextGroup style={{display: 'flow-root'}}>
            <ConsultingImage
              src={consultingImage}
              alt="Group of people around computers"
            />

            <DropBorder />
            <p>
              <BoldText>Consulting</BoldText>
            </p>
            <p>
              Aren’t sure if this is the right approach for your company?
            </p>
            <p>
              Preflight Technologies is not just about 1s and 0s, we
              believe that organizations only work when they have processes
              that are designed from the core out. If you’re interested in
              an in-depth look into your company’s software, our team is
              eager to walk through that process with you.
            </p>
          </TextGroup>
        </Content>
      </NormalSection>
      <NormalSection>
        <Content>
          <ContentTag>
            How It Works
          </ContentTag>
          <TextGroup style={{display: 'flow-root'}}>
    <ol>
      <li>
        Our process starts with People. We work with you and your team
        to understand what the needs are.
      </li>
      <li>
        Then we do the work. We believe in order to program
        something; we need to understand what is happening. We
        come along side you and your team, and we perform the
        tasks so that we can understand each objective along the way.
      </li>
      <li>
        We look for excuses. What are the excuses that keep your
        people from meeting their goals? Once we understand them,
        we develop software that removes those excuses.
      </li>
      <li>
        Our last step before development is design. We show you
        what we're thinking, and that can include process changes
        for your company. Our goal is to create efficiencies where
        we can.
      </li>
      <li>
        Once you approve it, we build it.
      </li>
      <li>
        Testing is our final step before you get to start your new,
        easier life at work.
      </li>
    </ol>
    <p>
      <BoldText>
        We help remove the day-to-day minutiae so you can focus
        on growing your business.
      </BoldText>
    </p>

          </TextGroup>
        </Content>
      </NormalSection>
    </Page>
  );
};

export default Solutions;
